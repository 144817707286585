<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <!-- <button
      type="button"
      class="btn btn-icon btn-secondary mr-2 ml-auto"
      @click="$router.push('/register')"
    >
     Sign-Up
    </button>
    <button
      type="button"
      class="btn btn-icon btn-primary mr-1"
      @click="$router.push('/login')"
    >
      Log-in 
    </button> -->
    <b-navbar-nav v-if="userInfo && userInfo.name" class="nav align-items-center ml-auto">
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ userInfo.name }}</p>
            <span class="user-status">Admin</span>
          </div>
          <b-avatar size="40" variant="light-primary" badge v-text="getAvatarText(userInfo.name)" class="badge-minimal"
            badge-variant="success" />
        </template>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MailIcon" class="mr-50" />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
          <span>Chat</span>
        </b-dropdown-item>

        <b-dropdown-divider /> -->

        <b-dropdown-item link-class="d-flex align-items-center" @click="Logout()">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import LogoutController from "@/controllers/LogoutController";
import AuthController from "@/controllers/AuthController";
import HomeController from "@/controllers/HomeController";
import { log } from "@/utils/log";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    DarkToggler,
  },
  data() {
    return {
      userInfo: {},
    };
  },

  created() {
    //this.checkUserAuthentication();
  },

  mounted() {
    this.getUserInfo();
    // setInterval(() => {
    //   this.checkUserAuthentication();
    // }, 30000);
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  methods: {
    getAvatarText(name) {
      var avatarText = name.charAt(0).toUpperCase()

      return avatarText
    },
    async Logout() {
      try {
        const logoutController = new LogoutController();
        await logoutController.logoutUser();
      } catch (error) {
        log(error);
      }
    },
    async checkUserAuthentication() {
      const authController = new AuthController();
      await authController.CheckValidation();
    },
    async getUserInfo() {
      const homeController = new HomeController();
      try {
        const response = await homeController.getUserData();
        this.userInfo = response;
      } catch (error) {
        log(error);
        this.userInfo = null;
      }
    },
  },
};
</script>
