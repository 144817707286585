export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Organizations',
    route: 'organizations',
    icon: 'ArchiveIcon',
  },
  {
    title: 'Departaments',
    route: 'departaments',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UserIcon',
  },
  {
    title: 'Projects',
    route: 'projects',
    icon: 'LayersIcon',
  },
  {
    title: 'Tasks',
    route: 'tasks',
    icon: 'ClipboardIcon',
  },
  {
    title: 'Activities',
    route: 'activities',
    icon: 'ClockIcon',
  },
  {
    title: 'Log',
    route: 'logs',
    icon: '',
  }
]
