import jwt from "@/auth/jwt/useJwt";
import { log } from "@/utils/log";
import { BASE_API } from '@/api/v1'

const axios = jwt.axiosIns;

class HomeController {
  async getUserData() {
    try {
      const response = await axios.get(BASE_API + 'me');
      return response.data;
    } catch (error) {
      log(error);
      return null;
    }
  }
}

export default HomeController;
