import axios from 'axios';
import { log } from "@/utils/log";
import { BASE_API } from '@/api/v1';

class LogoutController {
  async logoutUser() {
    try {
      const token = localStorage.getItem("access_token");
      await axios.post(BASE_API + 'logout', null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.removeItem("access_token");
      window.location.href = "/login";
    } catch (error) {
      log(error);
    }
  }
}

export default LogoutController;
